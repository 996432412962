import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import PropTypes from 'prop-types'
import IPQuoteDetails from 'components/broker/ProductDetails/IPQuoteDetails'
import MPQuoteDetails from 'components/broker/ProductDetails/MPQuoteDetails'
import LIQuoteDetails from 'components/broker/ProductDetails/LIQuoteDetails'
import PENQuoteDetails from 'components/broker/ProductDetails/PENQuoteDetails'
import HIQuoteDetails from 'components/broker/ProductDetails/HIQuoteDetails'
import BIQuoteDetails from 'components/broker/ProductDetails/BIQuoteDetails'
import HealthQuoteDetails from 'components/broker/ProductDetails/HealthQuoteDetails'
import GadgetQuoteDetails from 'components/broker/ProductDetails/GadgetQuoteDetails'
import PetQuoteDetails from 'components/broker/ProductDetails/PetQuoteDetails'
import TravelQuoteDetails from 'components/broker/ProductDetails/TravelQuoteDetails'
import PersonalDetails from 'components/broker/PersonalDetails'
import Activity from 'components/broker/Activity'
import AddNote from 'components/broker/AddNote'
import EditNote from 'components/broker/EditNote'
import AddCall from 'components/broker/AddCall'
import AddTask from 'components/broker/AddTask'
import AddPremiumModal from 'components/broker/AddPremiumModal'
import AddValueModal from 'components/broker/AddValueModal'
import AddReasonModal from 'components/broker/AddReasonModal'
import BrokerAPI from 'config/api/BrokerAPI'
import Loader from 'components/general/Loader'
import { onError } from 'config/lib/errorLib'
import { leadPipelineStages, pipelineStageColors } from 'config/constants/Constants'
import { NotificationManager } from 'react-notifications'
import { getProductString } from 'config/helpers/Helpers'
import { productOptions } from 'config/constants/Constants'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesButton from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesButton = makeStyles(componentStylesButton)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='down' ref={ref} {...props} />
})

function TabPanel(props) {
	const { children, value, index, ...other } = props
  
	return (
	  	<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			key={index}
			{...other}
	  	>
			{value === index && (
		  		<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
		  		</Box>
			)}
	  	</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
	  	id: `simple-tab-${index}`,
	  	'aria-controls': `simple-tabpanel-${index}`,
	};
}

const styles = {
	root: {
	  display: "flex",
	  "&$selected": {
		display: "flex"
	  }
	},
	selected: {}
};

export default function LeadModal(props) {
	const classes = {...useStyles(), ...useStylesDialog(), ...useStylesButton()}
	const api = new BrokerAPI()
	const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId || props.companyId

	const details = props.details
	const brokerOptions = {}
	for (const broker of props.brokers) {
		brokerOptions[broker.brokerId] = broker.firstName + ' ' + broker.lastName
	}
	console.log(brokerOptions)
	
	const [lead, setLead] = useState(props.lead)
	const [value, setValue] = useState(0)
    const [loading, setLoading] = useState(false)
	//const [showSecondPerson, setShowSecondPerson] = useState(props.lead.secondPerson ? true : false)
	const [tasks, setTasks] = useState(null)
	const [showAddNote, setShowAddNote]  = useState(false)
	const [showEditNote, setShowEditNote]  = useState(false)
	const [note, setNote] = useState(null)
	const [showAddCall, setShowAddCall]  = useState(false)
	const [showAddTask, setShowAddTask]  = useState(false)
	const [showQuoteContent, setShowQuoteContent] = useState(false)
	const [showAddPremiumModal, setShowAddPremiumModal] = useState(false)
	const [showAddValueModal, setShowAddValueModal] = useState(false)
	const [showAddReasonModal, setShowAddReasonModal] = useState(false)
	const [alert, setAlert] = useState(false)

	useEffect(() => {
        console.log(lead.activity.length)
		if (lead.activity.length > 0 || lead.tasks.length > 0) {
			handleTabChange(null, 1)
		}
    }, [])

	const handleTabChange = (event, newValue) => {
		setValue(newValue)
		if ((newValue === 1) && tasks === null) {
			if (lead.tasks.length > 0) {
				console.log('get tasks...')
				setLoading(true)
				getTasks()
			}
			else {
				setTasks([])
			}
		}
	}

	function updateLead(details) {
		setLead(details)
		props.updateLead(details)
	}

	async function updateStage(stage, lead, skipCheck = false) {
		console.log('update stage: ', lead)
		if (stage === 'won' && !skipCheck) {
			if (lead.product === 'pensions') {
				setShowAddValueModal(true)
			}
			else {
				setShowAddPremiumModal(true)
			}
		}
		else if (stage === 'lost' && !lead.lostReason) {
			setShowAddReasonModal(true)
		}
		else {
			try {
				//let result = lead.product === 'general' ? await api.updateGeneralStage(companyId, lead.date, lead.createdAt, stage) : await api.updateLeadStage(companyId, lead.product, lead.phone, stage)
				let result = await api.updateLeadStage(companyId, lead.product, lead.phone, stage)
				console.log(result)

				setLead(result)
				//setLoading(false)
				props.updateLead(result)
				NotificationManager.success('Stage updated')
			} catch(e) {
				onError(e)
			}
		}
	}

	async function updateBroker(newBroker) {
		console.log('update broker: ', newBroker)
		try {
			//let result = lead.product === 'general' ? await api.updateGeneralBroker(companyId, lead.date, lead.createdAt, newBroker, brokerOptions[newBroker]) : await api.updateLeadBroker(companyId, lead.product, lead.phone, newBroker, brokerOptions[newBroker])
			let result = await api.updateLeadBroker(companyId, lead.product, lead.phone, newBroker, brokerOptions[newBroker])
			console.log(result)

			setLead(result)
			//setLoading(false)
			props.updateLead(result)
			NotificationManager.success('Broker updated')
		} catch(e) {
			onError(e)
		}
	}

	async function updateProduct(newProduct) {
		console.log(newProduct)
		if (lead.phone && lead.phone.length > 0) {
			try {
				let success = await api.updateGeneralProduct(companyId, lead.date, lead.createdAt, newProduct)
				//let success = true
				console.log('success: ', success)

				if (success) {
					props.removeLead(lead, newProduct)
					props.close()
				}
				else {
					NotificationManager.success('Error updating product')
				}
			} catch(e) {
				onError(e)
			}
		}
		else {
			NotificationManager.success('Please add a phone number before updating product')
		}
	}

	function updateTasks(tasks) {
		setTasks(tasks)
	}

	async function getTasks() {
		console.log('get tasks...')
		if (lead.tasks.length > 0) {
			try {
				let result = await api.getTasks(companyId, lead.tasks, lead.brokerId)
				console.log(result)
				
				setTasks(result)
				setLoading(false)
			} catch(e) {
				onError(e)
			}
		}
	}

	async function openAddTask() {
		if (tasks === null) {
			await getTasks()
		}
		setShowAddTask(true)
	}

	function openEditNote(note) {
		setNote(note)
		setShowEditNote(true)
	}

	function showUpdateProductWarning(product) {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Are you sure?'
                onConfirm={() => updateProduct(product)}
                onCancel={() => setAlert(false)}
                cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='success'
                cancelBtnText='Cancel'
                confirmBtnText='Yes, Update Product'
                showCancel
                btnSize=''
            >
                This will move this lead to the {getProductString(product)} deal board permanently
            </ReactBSAlert>
        )
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			maxWidth='xl'
			className='lead-modal'
			//className='fixed-height-modal'
			//fullScreen
		>
			{alert}
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{lead.firstName + ' ' + lead.lastName} (<a href={'tel:' + lead.phone}>{lead.phone}</a> | <a href={'mailto:' + lead.email}>{lead.email}</a> | {lead.leadId})
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent style={{paddingTop: '0'}}>
				<Grid container alignItems='center' justifyContent='center' style={{marginBottom: '1rem'}}>
					<Grid item xs={3}>
						<FormControl variant='outlined' fullWidth direction='row'>
							<FormLabel>Lead Stage</FormLabel>
							<Select
								multiple={false}
								IconComponent={KeyboardArrowDown}
								value={lead.stage}
								onChange={(e) => updateStage(e.target.value, lead)}
								className='stage-select'
							>
								{Object.keys(leadPipelineStages).map((key) => {
									return (
										<MenuItem 
											value={key}
											classes={styles}
										>
											<Box className='stage-color' style={{backgroundColor: pipelineStageColors[key]}}></Box> 
											<Box>{leadPipelineStages[key]}</Box>
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</Grid>
					{details.permissions === 'admin' &&
						<Grid item xs={3}>
							<FormControl variant='outlined' fullWidth direction='row'>
								<FormLabel>Assigned Broker</FormLabel>
								<Select
									multiple={false}
									IconComponent={KeyboardArrowDown}
									value={lead.brokerId}
									onChange={(e) => updateBroker(e.target.value)}
								>
									{Object.keys(brokerOptions).map((key) => {
										return (<MenuItem value={key}>{brokerOptions[key]}</MenuItem>)
									})}
								</Select>
							</FormControl>
						</Grid>
					}
					{/*lead.product === 'general' &&
						<Grid item xs={3}>
							<FormControl variant='outlined' fullWidth direction='row'>
								<FormLabel>Product</FormLabel>
								<Select
									multiple={false}
									IconComponent={KeyboardArrowDown}
									value={lead.product}
									onChange={(e) => showUpdateProductWarning(e.target.value)}
								>
									{productOptions.map((option) => {
										return (<MenuItem value={option.value}>{option.label}</MenuItem>)
									})}
								</Select>
							</FormControl>
						</Grid>
					*/}
				</Grid>
				{(!showAddNote && !showAddCall && !showAddTask && !showEditNote) &&
					<Grid container>
						<Grid item xs={2}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs 
									value={value} 
									onChange={handleTabChange} 
									orientation='vertical'
									aria-label='basic tabs example'
								>
									<Tab label='Lead Info' {...a11yProps(0)} />
									<Tab label='Activity' {...a11yProps(1)} />
								</Tabs>
							</Box>
						</Grid>

						<Grid item xs={10}>
							<TabPanel value={value} index={0}>
								<Grid container>
									<Grid item xs={12}>
										{lead.product === 'income-protection' &&
											<IPQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{lead.product === 'mortgage-protection' &&
											<MPQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{lead.product === 'life-insurance' &&
											<LIQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{lead.product === 'health-insurance' &&
											<HealthQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{lead.product === 'pensions' &&
											<PENQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
												permissions={details.permissions}
											/>
										}
										{lead.product === 'home-insurance' &&
											<HIQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{lead.product === 'business-insurance' &&
											<BIQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{lead.product === 'gadget-insurance' &&
											<GadgetQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{lead.product === 'pet-insurance' &&
											<PetQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{lead.product === 'travel-insurance' &&
											<TravelQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
												companyId={companyId}
											/>
										}
										{/*lead.product === 'general' &&
											<GENQuoteDetails
												lead={lead}
												updateLead={(details) => updateLead(details)}
												showQuoteContent={showQuoteContent}
											/>
										*/}
									</Grid>
									<Grid item lg={12}>
										<PersonalDetails 
											lead={lead}
											updateLead={(details) => updateLead(details)}
											companyId={companyId}
										/>
									</Grid>
								</Grid>
							</TabPanel>
							<TabPanel value={value} index={1}>
								{!loading ? (
									<Activity
										lead={lead}
										tasks={tasks}
										editNote={(note) => openEditNote(note)}
										admin={props.admin}
									/>
								) : (
									<Grid container justifyContent='flex-start'>
										<Grid item xs={12}>
											<Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
												<Loader />
											</Box>
										</Grid>
									</Grid>
								)}
							</TabPanel>
						</Grid>
					</Grid>
				}

				<Grid container alignItems='center' justifyContent='center'>
					<Grid item xs={10}>
						{showAddNote &&
							<AddNote 
								lead={lead}
								updateLead={(lead) => updateLead(lead)}
								openActivity={() => handleTabChange(null, 1)}
								hide={() => setShowAddNote(false)}
							/>
						}

						{showEditNote &&
							<EditNote 
								lead={lead}
								note={note}
								updateLead={(lead) => updateLead(lead)}
								openActivity={() => handleTabChange(null, 1)}
								hide={() => setShowEditNote(false)}
							/>
						}

						{showAddCall &&
							<AddCall 
								lead={lead}
								updateLead={(lead) => updateLead(lead)}
								openActivity={() => handleTabChange(null, 1)}
								hide={() => setShowAddCall(false)}
							/>
						}

						{showAddTask &&
							<AddTask
								lead={lead}
								tasks={tasks}
								updateTasks={(tasks) => updateTasks(tasks)}
								openActivity={() => handleTabChange(null, 1)}
								hide={() => setShowAddTask(false)}
							/>
						}
					</Grid>
				</Grid>
			</DialogContent>
			
			{(!showAddCall && !showAddNote && !showAddTask) &&
				<DialogActions>
					<Button
						onClick={() => props.close()}
						color='primary'
					>
						Close
					</Button>

					<Box marginLeft='auto!important'>
                        <Button
							component={Box}
							onClick={() => setShowAddNote(true)}
							color='primary'
							variant='contained'
							disabled={props.admin}
						>
							Add Note
						</Button>
						<Button
							component={Box}
							onClick={() => setShowAddCall(true)}
							color='primary'
							marginLeft='0.5rem!important'
							variant='contained'
							disabled={props.admin}
						>
							Add Call
						</Button>
                        <Button
							component={Box}
							onClick={() => openAddTask()}
							color='primary'
							marginLeft='0.5rem!important'
							variant='contained'
							disabled={props.admin}
						>
							Add Task
						</Button>
					</Box>
				</DialogActions>
			}

			{showAddPremiumModal &&
                <AddPremiumModal 
                    isOpen={showAddPremiumModal}
                    close={() => setShowAddPremiumModal(false)}
                    showSuccessAlert={(text) => props.showSuccessAlert(text)}
					lead={lead}
                    updateLead={(lead) => updateLead(lead)}
					updateStage={(stage, lead, skipCheck) => updateStage(stage, lead, skipCheck)}
					companyId={companyId}
                />
            }
			{showAddValueModal &&
                <AddValueModal 
                    isOpen={showAddValueModal}
                    close={() => setShowAddValueModal(false)}
                    showSuccessAlert={(text) => props.showSuccessAlert(text)}
					lead={lead}
                    updateLead={(lead) => updateLead(lead)}
					updateStage={(stage, lead, skipCheck) => updateStage(stage, lead, skipCheck)}
					companyId={companyId}
                />
            }
			{showAddReasonModal &&
                <AddReasonModal 
                    isOpen={showAddReasonModal}
                    close={() => setShowAddReasonModal(false)}
                    showSuccessAlert={(text) => props.showSuccessAlert(text)}
					lead={lead}
                    updateLead={(lead) => updateLead(lead)}
					updateStage={(stage, lead) => updateStage(stage, lead)}
					companyId={companyId}
                />
            }
		</Dialog>
	)
}