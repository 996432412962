import React, { useState, useEffect, useContext } from 'react'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import BrokerAPI from 'config/api/BrokerAPI'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import LeadsTable from 'components/broker/LeadsTable'
import { NotificationManager } from 'react-notifications'
import { getPipelineCount, getProductString, getMoments } from 'config/helpers/Helpers'
import PeriodSelect from 'components/general/PeriodSelect'
import moment from 'moment-timezone'

import DetailsContext from 'config/context/DetailsContext'

const admins = [
    'cib@brokerdiary.io',
    'sbrett@campion.com',
    'shauna.flanagan@campion.com',
    'idunne@campion.com'
]

function Leads() {
    const theme = useTheme()
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const product = window.location.pathname.split('/')[1]
    console.log('product: ', product)

    const [details, setDetails] = useState(null)
    const [allLeads, setAllLeads] = useState([])
    //const [leads, setLeads] = useState([])
    const [showLeads, setShowLeads] = useState([])
    const [stage, setStage] = useState('new')
    const [pipelineCount, setPipelineCount] = useState({})
    const [broker, setBroker] = useState('allBrokers')
    const [brokers, setBrokers] = useState([])
    const [loadMore, setLoadMore] = useState(false)
    const [loading, setLoading] = useState(true)
    const [moreLeadsLoading, setMoreLeadsLoading] = useState(false)
    const [alert, setAlert] = useState(false)
    const [period, setPeriod] = useState('thisMonth')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [permissions, setPermissions] = useState('broker')

    useEffect(() => {
        getDetails()
    }, [])

    async function getDetails() {
        try {
            const details = await api.getDetails(companyId)
            console.log('details: ', details)

            let brokerId = details.permissions === 'broker' ? details.brokerId : null
            
            let permissions = 'broker'
            if (admins.includes(details.email)) {
                permissions = 'admin'
            }

            let dates = getMoments(period)
            console.log('dates: ', dates)
            let leads = [] 
            let loadMore = false
            if (permissions === 'admin') {
                leads = await api.getLeadsPeriod(product, companyId, dates.fromDate.valueOf(), dates.toDate.valueOf())
            }
            else {
                let response = await api.getLeadsLimit(product, companyId, brokerId, loadMore)
                console.log('response: ', response)
                leads = response.items
                loadMore = response.loadMore
            }
            console.log('leads: ', leads.length)

            // get leads from legacy 'general' table and combine with all other leads
            if (product === 'general' && companyId === 'ci') {
                let generalTableLeads = await api.getGeneral(companyId, brokerId)
                console.log('gen leads', generalTableLeads.items)
                leads.push(...generalTableLeads.items)
            }

            leads.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            const brokers = await api.getBrokers(companyId)
            console.log('brokers: ', brokers)

            updatePipelineCount(leads)
            setDetails(details)
            setAllLeads(leads)
            //setLeads(leads)
            setShowLeads(leads.filter((lead) => lead.stage === 'new'))
            setBrokers(brokers)
            setLoadMore(loadMore)
            setPermissions(permissions)
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function loadMoreLeads() {
        setMoreLeadsLoading(true)
        try {
            let brokerId = details.permissions === 'broker' ? details.brokerId : null
            const response = await api.getLeadsLimit(product, companyId, brokerId, loadMore)
            console.log('response: ', response)

            response.items.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            let combinedLeads = [...allLeads, ...response.items]
            console.log('combined: ', combinedLeads.length)

            let brokerLeads = []

            if (broker !== 'allBrokers') {
                brokerLeads = combinedLeads.filter(lead => lead.brokerId === broker)
            }
            else {
                brokerLeads = combinedLeads
            }

            let filteredLeads = brokerLeads.filter((lead) => lead.stage === stage)

            updatePipelineCount(brokerLeads)
            setAllLeads(combinedLeads)
            //setLeads(combinedLeads)
            setShowLeads(filteredLeads)
            setLoadMore(response.loadMore || false)
            //setLoading(false)
        } catch(e) {
            onError(e)
        }
        setMoreLeadsLoading(false)
    }

    async function refreshLeads() {
        try {
            let brokerId = details.permissions === 'broker' ? details.brokerId : null 

            let leads = []
            let loadMore = false
            if (permissions === 'admin') {
                leads = await api.getLeadsPeriod(product, companyId, fromDate.valueOf(), toDate.valueOf())
            }
            else {
                let response = await api.getLeadsLimit(product, companyId, brokerId, loadMore)
                leads = response.items
                loadMore = response.loadMore
            }
            console.log('leads: ', leads.length)

            // get leads from legacy 'general' table and combine with all other leads
            if (product === 'general' && companyId === 'ci') {
                let generalTableLeads = await api.getGeneral(companyId, brokerId)
                console.log('gen leads', generalTableLeads.items)
                leads.push(...generalTableLeads.items)
            }

            if (leads && leads.length > 0) {
                leads.sort((a, b) => {
                    return b.createdAt - a.createdAt
                })
            }

            let brokerLeads = []

            if (broker !== 'allBrokers') {
                brokerLeads = leads.filter(lead => lead.brokerId === broker)
            }
            else {
                brokerLeads = leads
            }

            let filteredLeads = brokerLeads.filter((lead) => lead.stage === stage)

            updatePipelineCount(brokerLeads)
            setAllLeads(leads)
            //setLeads(combinedLeads)
            setShowLeads(filteredLeads)
            setLoadMore(loadMore)
        } catch(e) {
            onError(e)
        }
    }

    async function updatePeriod(period, fromDate=null, toDate=null) {
            let brokerId = details.permissions === 'broker' ? details.brokerId : null 
            setPeriod(period)
            setMoreLeadsLoading(true) 
            let dates = period === 'custom' ? {
                fromDate: moment(fromDate).startOf('day'),
                toDate: moment(toDate).endOf('day')
            } : getMoments(period)
            
            try {
                let leads = await api.getLeadsPeriod(product, companyId, dates.fromDate.valueOf(), dates.toDate.valueOf())

                // get leads from legacy 'general' table and combine with all other leads
                if (product === 'general' && companyId === 'ci') {
                    let generalTableLeads = await api.getGeneral(companyId, brokerId)
                    console.log('gen leads', generalTableLeads.items)
                    leads.push(...generalTableLeads.items)
                }

                if (leads && leads.length > 0) {
                    leads.sort((a, b) => {
                        return b.createdAt - a.createdAt
                    })
                }

                let brokerLeads = []

                if (broker !== 'allBrokers') {
                    brokerLeads = leads.filter(lead => lead.brokerId === broker)
                }
                else {
                    brokerLeads = leads
                }

                let filteredLeads = brokerLeads.filter((lead) => lead.stage === stage)



                updatePipelineCount(brokerLeads)
                setAllLeads(leads)
                setShowLeads(filteredLeads)
                setFromDate(dates.fromDate)
                setToDate(dates.toDate)
                setMoreLeadsLoading(false)
            } catch(e) {
                onError(e)
            }
        }

    async function updateBroker(broker) {
        setLoading(true)
        console.log(broker)

        try {
            let brokerLeads = []

            if (broker !== 'allBrokers') {
                brokerLeads = allLeads.filter(lead => lead.brokerId === broker)
            }
            else {
                brokerLeads = allLeads
            }

            let filteredLeads = brokerLeads.filter((lead) => lead.stage === stage)
            
            updatePipelineCount(brokerLeads)
            setBroker(broker)
            setShowLeads(filteredLeads)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
	}

    async function updateStageMultiple(stage, leads) {
        console.log('update stage multiple')
        console.log(stage)
        console.log(leads)
        try {
            //let updated = product === 'general' ? await api.updateGeneralStageMultiple(companyId, leads, stage) : await api.updateLeadStageMultiple(companyId, product, leads, stage)
            let updated = await api.updateLeadStageMultiple(companyId, product, leads, stage)
            console.log('leads: ', updated)

            NotificationManager.success('Leads updated')
            refreshLeads()
        } catch(e) {
            onError(e)
        }
    }

    async function updateBrokerMultiple(brokerId, leads) {
        console.log('update broker multiple')
        console.log(brokerId)
        console.log(leads)
        let broker = brokers.find(broker => broker.brokerId === brokerId)
        let brokerName = broker.firstName + ' ' + broker.lastName
        console.log(brokerName)
        try {
            let updated = await api.updateLeadBrokerMultiple(companyId, product, leads, brokerId, brokerName)
            console.log('leads: ', updated)

            NotificationManager.success('Leads updated')
            refreshLeads()
        } catch(e) {
            onError(e)
        }
    }

    function updatePipelineCount(leads) {
        let count = getPipelineCount(leads)
        setPipelineCount(count)
    }

    function updateStage(stage, newLeads = null) {
        console.log('stage: ', stage)
        console.log('update stage leads: ', newLeads)

        let filteredLeads = newLeads ? newLeads : allLeads

        if (broker !== 'allBrokers') {
            filteredLeads = filteredLeads.filter(lead => lead.brokerId === broker)
        }

        updatePipelineCount(filteredLeads)
        setShowLeads(filteredLeads.filter((lead) => lead.stage === stage))
        setStage(stage)
    }

    function updateLead(lead) {
        //const index = product === 'general' ? allLeads.findIndex(oldLead => ((oldLead.date === lead.date) && (oldLead.createdAt === lead.createdAt))) : allLeads.findIndex(oldLead => ((oldLead.product === lead.product) && (oldLead.phone === lead.phone)))
        const index = allLeads.findIndex(oldLead => ((oldLead.product === lead.product) && (oldLead.phone === lead.phone)))
        console.log('lead index: ', index)

        let newLeads = [...allLeads]
        newLeads[index] = lead
        console.log(newLeads)
        
        setAllLeads(newLeads)
        updatePipelineCount(newLeads)
        updateStage(stage, newLeads)
    }

    function removeLead(lead, product) {
        //const index = product === 'general' ? allLeads.findIndex(oldLead => ((oldLead.date === lead.date) && (oldLead.createdAt === lead.createdAt))) : allLeads.findIndex(oldLead => ((oldLead.product === lead.product) && (oldLead.phone === lead.phone)))
        const index = allLeads.findIndex(oldLead => ((oldLead.product === lead.product) && (oldLead.phone === lead.phone)))
        console.log('lead index: ', index)

        let newLeads = [...allLeads]
        newLeads.splice(index, 1)
        console.log('new leads: ', newLeads)
        
        setAllLeads(newLeads)
        updatePipelineCount(newLeads)
        updateStage(stage, newLeads)
        showSuccessAlert('Lead has been moved to the ' + getProductString(product) + ' deal board')
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                //classes={{ root: classes.containerRoot }}
            >
                {alert}
                {!loading ? (
                    <Grid container justifyContent='flex-start'>
                        {details.permissions === 'admin' &&
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    {permissions === 'admin' &&
                                        <Grid item xs={12} md={2}>
                                            <PeriodSelect
                                                period={period}
                                                updatePeriod={(period, fromDate, toDate) => updatePeriod(period, fromDate, toDate)}
                                                fromDate={fromDate}
                                                toDate={toDate}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '0'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={broker}
                                                    onChange={(e) => updateBroker(e.target.value)}
                                                >
                                                    <MenuItem value={'allBrokers'} key={'all'}>All Brokers</MenuItem>
                                                    {brokers.map((broker, i) => {
                                                        return (<MenuItem value={broker.brokerId} key={i}>{broker.firstName + ' ' + broker.lastName}</MenuItem>)
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                        <Box
                                            fontSize='12px'
                                            color={theme.palette.gray[600]}
                                            //marginBottom='0.2rem'
                                            //marginLeft='1rem'
                                            marginTop='0.5rem'
                                            textAlign='left'
                                            //className='online-discount'
                                        >
                                            <Box component='small' fontSize='100%'>
                                                Broker
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <LeadsTable 
                                allLeads={allLeads}
                                leads={showLeads}
                                pipelineCount={pipelineCount}
                                stage={stage}
                                updateStage={(stage) => updateStage(stage)}
                                updateLead={(lead) => updateLead(lead)}
                                removeLead={(lead, product) => removeLead(lead, product)}
                                updateStageMultiple={(stage, selected) => updateStageMultiple(stage, selected)}
                                updateBrokerMultiple={(broker, selected) => updateBrokerMultiple(broker, selected)}
                                updatePipelineCount={(leads) => updatePipelineCount(leads)}
                                refreshLeads={() => refreshLeads()}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                brokers={brokers}
                                details={details}
                                product={product}
                                companyId={companyId}
                            />
                        </Grid>
                        {loadMore &&
                            <Grid item xs={12} style={{textAlign: 'center', paddingBottom: '3rem'}}>
                                <Button
                                    component={Box}
                                    onClick={() => loadMoreLeads()}
                                    color='secondary'
                                    variant='contained'
                                    disabled={moreLeadsLoading}
                                >
                                    {!moreLeadsLoading ? (
                                        <>Load More Leads</>
                                    ) : (
                                        <Loader height={12} />
                                    )}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    )
}

export default Leads